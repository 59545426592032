import React from 'react';
import { sendRequest } from '../utils';

export default function SubscribeButton() {
  const handleSubscribe = async () => {
    try {
      const opts = {
        success_url: `${window.location.origin}/settings`,
        cancel_url: `${window.location.origin}/settings?subscription=cancel`,
      };
      sendRequest('subscriptions/checkout_session', 'POST', opts)
        .then((response) => {
          if (!response.data.checkout_url) {
            console.error('No checkout_url returned from the server');
            alert('An error occurred, please try again later or contact our support if the issue persists.');
            return;
          }

          window.location.href = response.data.checkout_url;
        });
    } catch (err) {
      console.error('Unexpected Error:', err);
      alert('An error occurred, please try again later or contact our support if the issue persists.');
    }
  };

  return (
    <button onClick={handleSubscribe} className="btn btn-small white black-text" type="button">
      Subscribe
    </button>
  );
}

import React from 'react';
import ChangePasswordForm from './Settings/ChangePasswordForm';
import GeneralSettingsForm from './Settings/GeneralSettingsForm';
import BillingSettings from './Settings/BillingSettings';
import SubscriptionSettings from './Settings/SubscriptionSettings';

const MySettings = () => {
  // Check if there is subscription query param
  const urlParams = new URLSearchParams(window.location.search);
  const subscription = urlParams.get('subscription');
  // We have to use the session_id query param to show the subscription success message
  // because we can't use GET query params as success_url in the Stripe Checkout
  // otherwise Stripe will redirect to something like
  // <HOST>/settings?subscription=success?session_id=cs_test_XXX
  const sessionId = urlParams.get('session_id');

  return (
    <div className="container mt2 mb2">

      <div className="row">
        <div className="col s12">
          <h4 className="mb4">My Settings</h4>

          {
            sessionId && (
              <div className="card-panel green lighten-5">
                <span className="green-text text-darken-2">
                  You have successfully subscribed!
                </span>
              </div>
            )
          }

          {subscription === 'cancel' && (
            <div className="card-panel red lighten-5">
              <span className="red-text text-darken-2">
                You did not complete the subscription process or something went wrong.
                Please try again or contact support.
              </span>
            </div>
          )}

          <ul className="tabs">
            <li className="tab col s3"><a className="active" href="#subscription">Subscription</a></li>
            <li className="tab col s3"><a href="#general">General</a></li>
            <li className="tab col s3"><a href="#password">Security</a></li>
            <li className="tab col s3"><a href="#billing">Billing</a></li>
          </ul>
        </div>

        <div id="subscription" className="col s12">
          <SubscriptionSettings />
        </div>

        <div id="general" className="col s12">
          <GeneralSettingsForm />
        </div>

        <div id="password" className="col s12">
          <ChangePasswordForm />
        </div>

        <div id="billing" className="col s12">
          <BillingSettings />
        </div>
      </div>
    </div>
  );
};

export default MySettings;

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import M from '@materializecss/materialize';
import Table from '../../../Components/Table';
import EditTimerModal from './EditTimerModal';
import { useUser } from '../../../hooks';
import TimerHistoryModal from './TimerHistoryModal';

const getPrettyTime = (totalMinutes) => {
  const diff = moment.duration(totalMinutes * 60 * 1000);

  const days = diff.days();
  const hours = (`0${days * 24 + diff.hours()}`).slice(-2);
  const minutes = (`0${diff.minutes()}`).slice(-2);
  const seconds = (`0${diff.seconds()}`).slice(-2);

  return `${hours}:${minutes}:${seconds}`;
};

const TimerActions = ({ cell: { row: { original } }, onTimerUpdate }) => {
  const { user } = useUser();

  React.useEffect(() => {
    // Initialize modals
    const elems = document.querySelectorAll('.modal');
    M.Modal.init(elems);
  });

  return (
    <>
      <button
        type="button"
        className="btn black modal-trigger"
        data-target={`edit-timer-modal-${original.id}`}
      >
        Edit
      </button>

      <div id={`edit-timer-modal-${original.id}`} className="modal">
        <EditTimerModal
          patientId={original.patient_id}
          timerId={original.id}
          currentStartDate={original.started_at}
          currentEndDate={original.ended_at}
          onUpdateTimer={onTimerUpdate}
        />
      </div>

      {
        user && user.is_staff && (
          <>
            <button
              type="button"
              className="btn white black-text ml1 modal-trigger"
              data-target={`history-timer-modal-${original.id}`}
            >
              View History
            </button>

            <div id={`history-timer-modal-${original.id}`} className="modal modal-fixed-footer">
              <TimerHistoryModal
                timerId={original.id}
                history={original.history}
                currentStartDate={original.started_at}
                currentEndDate={original.ended_at}
              />
            </div>
          </>
        )
      }
    </>
  );
};

TimerActions.propTypes = {
  cell: PropTypes.shape({
    row: PropTypes.shape({
      original: PropTypes.shape({
        id: PropTypes.number.isRequired,
        patient_id: PropTypes.string.isRequired,
        started_at: PropTypes.string.isRequired,
        ended_at: PropTypes.string.isRequired,
        history: PropTypes.arrayOf(PropTypes.object).isRequired,
      }),
    }),
  }).isRequired,
  onTimerUpdate: PropTypes.func.isRequired,
};

const TimersTable = ({ timersData, onTimerUpdate }) => {
  const [timers, setTimers] = React.useState([]);

  React.useEffect(() => {
    setTimers(timersData);
  }, [timersData]);

  const columns = [
    {
      Header: 'Timer ID',
      accessor: 'id',
      className: 'center relative',
    },
    {
      Header: 'Date Started',
      accessor: 'started_at',
      className: 'center relative',
    },
    {
      Header: 'Date Ended',
      accessor: 'ended_at',
      className: 'center relative',
    },
    {
      Header: 'Duration',
      accessor: 'time_spent',
      className: 'center relative',
    },
    {
      Header: 'History',
      accessor: 'history',
      className: 'center relative',
    },
    {
      Header: 'Actions',
      // Cell: TimerActions,
      Cell: (props) => <TimerActions {...props} onTimerUpdate={onTimerUpdate} />,
      className: 'center relative',
    },
  ];

  const data = timers.map((timer) => ({
    id: timer.id,
    patient_id: timer.patient.id,
    started_at: timer.started_at.format('MM/DD/YYYY h:mm A'),
    time_spent: getPrettyTime(timer.time_spent),
    ended_at: timer.ended_at.format('MM/DD/YYYY h:mm A'),
    history: timer.history,
  }));

  return (
    <Table columns={columns} hiddenColumns={['history']} data={data} />
  );
};

TimersTable.propTypes = {
  timersData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      started_at: PropTypes.object.isRequired,
      ended_at: PropTypes.object.isRequired,
      time_spent: PropTypes.number.isRequired,
    }),
  ).isRequired,
  onTimerUpdate: PropTypes.func.isRequired,
};

export default TimersTable;

import React from 'react';
import { logout, sendRequest, useAuth } from './utils';

export const useFetch = (path, method, body) => {
  const [status, setStatus] = React.useState('idle');
  const [error, setError] = React.useState('');
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    if (!path) return;

    const fetchData = async () => {
      setStatus('fetching');
      const data = await sendRequest(path, method, body);
      try {
        if (data.status === 'success') {
          setData(data);
          setStatus('fetched');
        } else {
          setStatus('failed');
          setError(data.errors);
        }
      } catch {
        setStatus('failed');
        setError('Something went wrong while fetching data');
      }
    };

    fetchData();
  }, [path]);

  return [status, data, error];
};

export const useFetchAlertZones = (patientId, type, field = null) => {
  const [status, data, error] = useFetch(`connections/patients/${patientId}/alert_zones`, 'GET');

  const alertZones = data.data || [];

  if (Array.isArray(alertZones) && alertZones.length) {
    if (type) {
      let filtered = alertZones.filter((az) => az.entry_type === type);
      if (field) {
        filtered = filtered.filter((az) => az.field_name === field);
      }
      return [status, filtered, error];
    }
  }

  return [status, data.alertzones || [], error];
};

export function useUser({ fetchSubscription = false } = {}) {
  const [logged] = useAuth();
  const [user, setUser] = React.useState(null);
  const [subscription, setSubscription] = React.useState(null);

  React.useEffect(() => {
    if (!logged) {
      // If the user is not logged in, reset state and skip fetching.
      setUser(null);
      setSubscription(null);
      return;
    }

    // 1) Load basic user information
    sendRequest('users/me', 'GET')
      .then((response) => {
        if (response.status === 'success') {
          setUser(response.data);

          // 2) Optionally fetch subscription details
          if (fetchSubscription) {
            sendRequest('subscriptions/current', 'GET')
              .then((resp) => {
                if (resp.status === 'success') {
                  setSubscription(resp.data);
                } else {
                  setSubscription(null);
                  // E.g. no subscription found or error from server
                }
              })
              .catch((subErr) => {
                // eslint-disable-next-line no-console
                console.log('Error fetching subscription:', subErr);
                setSubscription(null);
              });
          }
        } else {
          // eslint-disable-next-line no-console
          console.log('Error while fetching user data');
          logout();
          localStorage.clear(); // or your specific cleanup logic
        }
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log('Error:', err);
        setUser(null);
        setSubscription(null);
      });
  }, [logged, fetchSubscription]);

  return { user, subscription };
}

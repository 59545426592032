import M from '@materializecss/materialize';
import React from 'react';

import { sendRequest } from '../../utils';
import BillingReportModal from './BillingReportModal';

function BillingSettings() {
  const [message, setMessage] = React.useState('');
  const [error, setError] = React.useState('');

  const [user, setUser] = React.useState({});

  React.useEffect(() => {
    // Initialize form fields
    M.updateTextFields();

    // Initialize the modal
    const elems = document.querySelectorAll('.modal');
    M.Modal.init(elems);
  }, [user]);

  React.useEffect(() => {
    sendRequest('users/me', 'GET')
      .then((resp) => {
        setUser(resp.data.doctor_profile.practice);
      });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setMessage('');
    setError('');

    // Leave only fields presented in Billing form
    const opts = {
      doctor_profile: {
        practice: {
          auto_gen_code_99454: user.auto_gen_code_99454,
          auto_gen_code_99457: user.auto_gen_code_99457,
          auto_gen_code_99458: user.auto_gen_code_99458,
        },
      },
    };

    sendRequest('users/me', 'PUT', opts)
      .then((response) => {
        if (response.status === 'success') {
          setMessage('Settings updated successfully');
        } else {
          setError(response.message);
        }
      })
      .catch(() => setError('Something went wrong'));
  };

  return (
    <div className="pt2 pb2">
      <div className="row">
        <form className="col s12" onSubmit={handleSubmit}>
          <h5 className="mb3">Billing Settings</h5>
          <div className={`form-messages ${message ? '' : 'hide'}`}>{message}</div>
          <div className={`form-errors ${error ? '' : 'hide'}`}>{error}</div>

          <div className="row mt3 mb4">
            <div className="col s12 m4">
              <label>
                <input
                  id="auto_gen_code_99454"
                  name="auto_gen_code_99454"
                  type="checkbox"
                  className="validate"
                  checked={user.auto_gen_code_99454 || false}
                  onChange={(e) => setUser({ ...user, auto_gen_code_99454: e.target.checked })}
                />
                <span>99454</span>
              </label>
            </div>

            <div className="col s12 m4">
              <label>
                <input
                  id="auto_gen_code_99457"
                  name="auto_gen_code_99457"
                  type="checkbox"
                  className="validate"
                  checked={user.auto_gen_code_99457 || false}
                  onChange={(e) => setUser({ ...user, auto_gen_code_99457: e.target.checked })}
                />
                <span>99457</span>
              </label>
            </div>

            <div className="col s12 m4">
              <label>
                <input
                  id="auto_gen_code_99458"
                  name="auto_gen_code_99458"
                  type="checkbox"
                  className="validate"
                  checked={user.auto_gen_code_99458 || false}
                  onChange={(e) => setUser({ ...user, auto_gen_code_99458: e.target.checked })}
                />
                <span>99458</span>
              </label>
            </div>
          </div>

          <button type="submit" className="btn black mr3">Save</button>

          <button type="button" className="btn black modal-trigger" data-target="modal1">Generate New Report</button>
        </form>
      </div>

      <div id="modal1" className="modal">
        <BillingReportModal />
      </div>

    </div>
  );
}

export default BillingSettings;

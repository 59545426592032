import moment from 'moment';
import Plot from 'react-plotly.js';
import React from 'react';
import PropTypes from 'prop-types';

import NoData from '../../Components/NoData';
import {
  calculateAverageForKey, getAlertZonesPlots, getPlotData, reducer, round, sendRequest,
} from '../../utils';
import Loader from '../../Components/Loader';
import { useFetchAlertZones } from '../../hooks';
import { ENTRY_TYPE_TO_CODE_MAPPING } from './constants';

const WeightPlot = ({
  patient, startDate, endDate, isActive, forPrinting = false,
}) => {
  const [state, dispatch] = React.useReducer(
    reducer,
    { data: [], isLoading: true, isError: false },
  );
  const [, alertZones] = useFetchAlertZones(patient.patient.id, 3, 'Weight');

  const [type, setType] = React.useState('date');

  React.useEffect(() => {
    if (!isActive) {
      // Do not load anything while not active
      return;
    }

    dispatch({ type: 'FETCH_INIT' });

    const opts = {
      entry_type: ENTRY_TYPE_TO_CODE_MAPPING.Weight,
      from_date: moment(startDate).utc().format(),
      to_date: moment(endDate).utc().format(),
    };

    sendRequest(`connections/patients/${patient.patient.id}/entries`, 'GET', opts)
      .then((response) => {
        if (response.status === 'error') {
          dispatch({
            type: 'FETCH_FAILURE',
            error: response.message,
            payload: [],
          });
        } else {
          const rawData = response.data;

          rawData
            .map((i) => ({
              ...i,
              created_at: moment(i.created_at),
            }))
            .sort((a, b) => {
              if (a.created_at > b.created_at) {
                return 1;
              }
              if (a.created_at < b.created_at) {
                return -1;
              }
              return 0;
            });

          // Hacky: replace zero or empty weight values with weight from user profile
          rawData.forEach((i) => {
            if (!i.weight) {
              // eslint-disable-next-line no-param-reassign
              i.weight = patient.patient.patient_profile.weight_in_lbs;
            }
          });

          dispatch({
            type: 'FETCH_SUCCESS',
            payload: rawData,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: 'FETCH_FAILURE', error: typeof error === 'object' ? error.toString() : error });
      });

    setType(moment(endDate).diff(moment(startDate), 'days') < 2 ? 'time' : 'date');
  }, [startDate, endDate, isActive]);

  React.useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  }, [isActive]);

  if (state.isLoading) {
    return (
      <Loader />
    );
  }

  if (!state.data.length) {
    return (
      <NoData />
    );
  }

  let plot = getPlotData({
    rawData: state.data,
    xAttr: 'created_at',
    yAttr: 'weight',
    label: 'Weight',
    color: '#2196f3',
    aggregate: true,
    summarize: false,
  });

  // Add alert zones
  const alertZonePlots = getAlertZonesPlots(alertZones, plot[0].x, 'lbs', '#ef9a9a');

  plot = [...plot, ...alertZonePlots.flat()];

  const averageWeight = round(calculateAverageForKey(state.data, 'weight'), 2);

  const minValue = Math.min(...plot[0]?.y);
  const maxValue = Math.max(...plot[0]?.y);
  const mediumValue = round((minValue + maxValue) / 2);

  return (
    <>
      <div>
        <div className="col s12 mb4">
          <Plot
            data={plot}
            style={{ width: '100%' }}
            layout={{
              width: forPrinting ? '267mm' : null,
              legend: { x: 0.01, y: -0.5, orientation: 'h' },
              margin: {
                l: 50, r: 20, b: 50, t: 50, pad: 2,
              },
              autosize: true,
              yaxis: {
                title: 'Weight (lbs)',
                nticks: 3,
                tickvals: [minValue, mediumValue, maxValue],
              },
              xaxis: {
                title: type === 'time' ? 'Time' : 'Date',
                tickformat: type === 'time' ? '%-I:%M %p' : '%m/%d/%Y',
              },
            }}
            config={{
              displayModeBar: false,
            }}
          />
        </div>
      </div>

      <div className="row mt4">
        <div className="col s12 m6 offset-m3">
          <div className="card blue-grey">
            <div className="card-content white-text">
              <span className="card-title text-center">Average Weight</span>
              <div className="center-align">
                {
                state.data.length
                  ? (
                    <>
                      <h4>{averageWeight}</h4>
                      lbs
                    </>
                  )
                  : <h4>Not Enough Data</h4>
              }
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="page-break-after"> </div>

      <div className="row mt4">
        <WeightMetricsTable data={state.data} />
      </div>
    </>
  );
};

WeightPlot.propTypes = {
  patient: PropTypes.shape({
    patient: PropTypes.shape({
      id: PropTypes.string.isRequired,
      patient_profile: PropTypes.shape({
        weight_in_lbs: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  isActive: PropTypes.bool.isRequired,
  forPrinting: PropTypes.bool,
};

WeightPlot.defaultProps = {
  forPrinting: false,
};

const WeightMetricsTable = ({ data }) => {
  // Leave only records with weight_data filled
  const filteredData = data
    .filter((i) => i.weight_data)
    .map((i) => i.weight_data);

  const aveHeartRate = round(calculateAverageForKey(filteredData, 'heartRate'), 0);
  const aveBoneDensity = round(calculateAverageForKey(filteredData, 'boneMuscle'), 2);
  const aveBodyFat = round(calculateAverageForKey(filteredData, 'bodyFatPercent'), 2);
  const aveVisceralFat = round(calculateAverageForKey(filteredData, 'visceralFat'), 2);
  const aveWaterContent = round(calculateAverageForKey(filteredData, 'waterContent'), 2);
  const aveBMI = round(calculateAverageForKey(filteredData, 'bmi'), 2);
  const aveBMR = round(calculateAverageForKey(filteredData, 'bmr'), 2);
  const aveBoneMass = round(calculateAverageForKey(filteredData, 'boneMass'), 2);
  const aveBodyAge = round(calculateAverageForKey(filteredData, 'bodyAge'), 2);
  const aveProteinPercent = round(calculateAverageForKey(filteredData, 'proteinPercent'), 2);
  const aveMuscleMass = round(calculateAverageForKey(filteredData, 'muscleMass'), 2);
  const aveMuscleRate = round(calculateAverageForKey(filteredData, 'muscleRate'), 2);
  const aveSkeletalMusclePercent = round(calculateAverageForKey(filteredData, 'skeletalMusclePercent'), 2);
  const aveSubcutaneousFatPercent = round(calculateAverageForKey(filteredData, 'subcutaneousFatPercent'), 2);

  return (
    <table className="rounded-table z-depth-1">
      <tbody>
        <tr>
          <td className="pt2 pb2">
            <span className="d-block m0 text-center h5 font-weight-bold">
              {
              aveVisceralFat
                ? (
                  <>
                    {aveVisceralFat}
                    <small className="font-weight-normal"> %</small>
                  </>
                )
                : <small className="font-weight-normal">-</small>
            }
            </span>
            <span className="d-block text-center grey-text">Heart Rate</span>
          </td>

          <td className="pt2 pb2">
            <span className="d-block m0 text-center h5 font-weight-bold">
              {
              aveBMI
                ? (
                  <>
                    {aveBMI}
                  </>
                )
                : <small className="font-weight-normal">-</small>
            }
            </span>
            <span className="d-block text-center grey-text">BMI</span>
          </td>
        </tr>

        <tr>
          <td className="pt2 pb2">
            <span className="d-block m0 text-center h5 font-weight-bold">
              {
              aveHeartRate
                ? (
                  <>
                    {aveHeartRate}
                    <small className="font-weight-normal"> bpm</small>
                  </>
                )
                : <small className="font-weight-normal">-</small>
            }
            </span>
            <span className="d-block text-center grey-text">Heart Rate</span>
          </td>

          <td className="pt2 pb2">
            <span className="d-block m0 text-center h5 font-weight-bold">
              {
              aveBoneDensity
                ? (
                  <>
                    {aveBoneDensity}
                    <small className="font-weight-normal"> %</small>
                  </>
                )
                : <small className="font-weight-normal">-</small>
            }
            </span>
            <span className="d-block text-center grey-text">Bone Muscle</span>
          </td>
        </tr>

        <tr>
          <td className="pt2 pb2">
            <span className="d-block m0 text-center h5 font-weight-bold">
              {
              aveBodyFat
                ? (
                  <>
                    {aveBodyFat}
                    <small className="font-weight-normal"> %</small>
                  </>
                )
                : <small className="font-weight-normal">-</small>
            }
            </span>
            <span className="d-block text-center grey-text">Body Fat</span>
          </td>

          <td className="pt2 pb2">
            <span className="d-block m0 text-center h5 font-weight-bold">
              {
              aveWaterContent
                ? (
                  <>
                    {aveWaterContent}
                    <small className="font-weight-normal"> %</small>
                  </>
                )
                : <small className="font-weight-normal">-</small>
            }
            </span>
            <span className="d-block text-center grey-text">Body Water</span>
          </td>
        </tr>

        <tr>
          <td className="pt2 pb2">
            <span className="d-block m0 text-center h5 font-weight-bold">
              {
              aveBMR
                ? (
                  <>
                    {aveBMR}
                    <small className="font-weight-normal"> kcal</small>
                  </>
                )
                : <small className="font-weight-normal">-</small>
            }
            </span>
            <span className="d-block text-center grey-text">BMR</span>
          </td>

          <td className="pt2 pb2">
            <span className="d-block m0 text-center h5 font-weight-bold">
              {
              aveBoneMass
                ? (
                  <>
                    {aveBoneMass}
                    <small className="font-weight-normal"> %</small>
                  </>
                )
                : <small className="font-weight-normal">-</small>
            }
            </span>
            <span className="d-block text-center grey-text">Bone Density</span>
          </td>
        </tr>

        <tr>
          <td className="pt2 pb2">
            <span className="d-block m0 text-center h5 font-weight-bold">
              {
              aveBodyAge
                ? (
                  <>
                    {aveBodyAge}
                  </>
                )
                : <small className="font-weight-normal">-</small>
            }
            </span>
            <span className="d-block text-center grey-text">Body Age</span>
          </td>

          <td className="pt2 pb2">
            <span className="d-block m0 text-center h5 font-weight-bold">
              {
              aveProteinPercent
                ? (
                  <>
                    {aveProteinPercent}
                    <small className="font-weight-normal"> %</small>
                  </>
                )
                : <small className="font-weight-normal">-</small>
            }
            </span>
            <span className="d-block text-center grey-text">Protein</span>
          </td>
        </tr>

        <tr>
          <td className="pt2 pb2">
            <span className="d-block m0 text-center h5 font-weight-bold">
              {
              aveMuscleMass
                ? (
                  <>
                    {aveMuscleMass}
                    <small className="font-weight-normal"> lb</small>
                  </>
                )
                : <small className="font-weight-normal">-</small>
            }
            </span>
            <span className="d-block text-center grey-text">Muscle Mass</span>
          </td>

          <td className="pt2 pb2">
            <span className="d-block m0 text-center h5 font-weight-bold">
              {
              aveMuscleRate
                ? (
                  <>
                    {aveMuscleRate}
                    <small className="font-weight-normal"> %</small>
                  </>
                )
                : <small className="font-weight-normal">-</small>
            }
            </span>
            <span className="d-block text-center grey-text">Muscle Rate</span>
          </td>
        </tr>

        <tr>
          <td className="pt2 pb2">
            <span className="d-block m0 text-center h5 font-weight-bold">
              {
              aveSkeletalMusclePercent
                ? (
                  <>
                    {aveSkeletalMusclePercent}
                    <small className="font-weight-normal"> %</small>
                  </>
                )
                : <small className="font-weight-normal">-</small>
            }
            </span>
            <span className="d-block text-center grey-text">Skeletal Muscle</span>
          </td>

          <td className="pt2 pb2">
            <span className="d-block m0 text-center h5 font-weight-bold">
              {
              aveSubcutaneousFatPercent
                ? (
                  <>
                    {aveSubcutaneousFatPercent}
                    <small className="font-weight-normal"> %</small>
                  </>
                )
                : <small className="font-weight-normal">-</small>
            }
            </span>
            <span className="d-block text-center grey-text">Subcutaneous Fat</span>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

WeightMetricsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    weight_data: PropTypes.shape({
      heartRate: PropTypes.number,
      boneMuscle: PropTypes.number,
      bodyFatPercent: PropTypes.number,
      visceral_fat: PropTypes.number,
      waterContent: PropTypes.number,
      bmi: PropTypes.number,
      bmr: PropTypes.number,
    }),
  })).isRequired,
};

export default WeightPlot;

// import * as d3 from 'd3';
import { createAuthProvider } from 'react-token-auth';

const { REACT_APP_API_URL } = process.env;

export const [useAuth, authFetch, login, logout] = createAuthProvider({
  accessTokenKey: 'access',
  onUpdateToken: (token) => fetch(`${REACT_APP_API_URL}/token/refresh/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ refresh: token.refresh }),
  })
    .then((r) => r.json())
    .then((data) => data.data),
});

export function calculateLinearRegression(x, y) {
  const lr = {};
  const n = y.length;
  let sumX = 0;
  let sumY = 0;
  let sumXY = 0;
  let sumXX = 0;
  let sumYY = 0;

  for (let i = 0; i < y.length; i += 1) {
    sumX += x[i];
    sumY += y[i];
    sumXY += (x[i] * y[i]);
    sumXX += (x[i] * x[i]);
    sumYY += (y[i] * y[i]);
  }

  lr.sl = (n * sumXY - sumX * sumY) / (n * sumXX - sumX * sumX);
  lr.off = (sumY - lr.sl * sumX) / n;
  lr.r2 = (n * sumXY - sumX * sumY) / Math.sqrt((n * sumXX - sumX * sumX) * (n * sumYY - sumY * sumY)) ** 2;

  return lr;
}

export function calculateTrend(x, y) {
  if ((x.length !== y.length) || x.length < 2) return [[], []];

  const xIndexes = x.map((e, i) => i);

  const lr = calculateLinearRegression(xIndexes, y);

  const fitFrom = x[0];
  const fitTo = x[x.length - 1];

  return [
    [fitFrom, fitTo],
    [Math.round(lr.off), Math.round(lr.sl * (x.length - 1) + lr.off)],
  ];
}

export function sendRequest(route, method = 'GET', body = {}, withAuth = true, form = false) {
  let url = `${REACT_APP_API_URL}/${route}`;

  if (!url.endsWith('/')) {
    url += '/';
  }

  // Build fetch options
  const params = {
    method,
    headers: {
      'Time-Zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  };

  // If it is not form data, set content type
  if (!form) {
    params.headers['Content-Type'] = 'application/json';
  }

  // If it's a GET, convert body to query string
  if (method === 'GET') {
    const query = new URLSearchParams(body).toString();
    url += `?${query}`;
  } else if (form) {
    // If form data, pass it directly
    params.body = body;
  } else {
    // Otherwise JSON
    params.body = JSON.stringify(body);
  }

  const fetchFn = withAuth ? authFetch : fetch;

  return fetchFn(url, params)
    .then(async (r) => {
      // If we get a 401, the library will attempt to refresh automatically.
      // If refresh fails, it will remove the tokens.

      // For non-file responses, get text
      const disposition = r.headers.get('Content-Disposition');
      const isFile = disposition && disposition.includes('attachment');

      if (isFile) {
        // Return a blob for file downloads
        const blob = await r.blob();
        const filename = disposition.split('filename=')[1]?.replace(/['"]/g, '') || 'download.bin';
        return { blob, filename };
      }

      // Otherwise parse text -> JSON or text
      const text = await r.text();
      return { text, status: r.status };
    })
    .then((response) => {
      // If it's a file response, just pass it through
      if (response.blob) {
        return response;
      }

      // Otherwise parse JSON
      try {
        if (response.text) {
          return JSON.parse(response.text);
        }
        return response.text;
      } catch (err) {
        throw Error('Our API is down. Please try again later.');
      }
    });
}

export const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_INIT':
      return {
        ...state,
        isLoading: true,
        isError: false,
        reload: action.reload || false,
        data: action.payload || state.data,
        message: '',
        error: '',
      };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        isLoading: false,
        isError: false,
        reload: action.reload || false,
        data: action.payload || state.data,
        message: action.message,
        error: '',
      };
    case 'FETCH_FAILURE':
      return {
        ...state,
        isLoading: false,
        isError: true,
        reload: action.reload || false,
        data: action.payload || state.data,
        message: '',
        error: action.error,
      };
    default:
      throw new Error();
  }
};

// Get cm
export const feetToCm = (feets, inches) => Math.round((feets * 12 + inches) * 2.54);

// Get feet and inches
export const cmToFeet = (cms) => {
  const realFeet = ((cms * 0.393700) / 12);
  const feets = Math.floor(realFeet);
  const inches = Math.round((realFeet - feets) * 12);
  return [feets, inches];
};

export const round = (number, digits = 0) => (
  Math.round(number * (10 ** digits)) / (10 ** digits)
);

export const lbToKg = (lbs) => round(lbs / 2.205, 2);
export const kgToLb = (kgs) => round(kgs * 2.205, 2);

export const groupBy = (xs, key) => xs.reduce(
  (rv, x) => {
    // eslint-disable-next-line no-param-reassign
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  },
  {},
);

export const calculateAverage = (arr) => arr.reduce((p, c) => p + c, 0) / arr.length;

export const prepareDataForPlot = (data, dateAttr, valueAttr, summarize = true) => {
  const groupByDate = (acc, current) => {
    if (current[dateAttr] in acc) {
      acc[current[dateAttr]].push(Number.parseFloat(current[valueAttr]));
    } else {
      acc[current[dateAttr]] = [Number.parseFloat(current[valueAttr])];
    }
    return acc;
  };

  // Data grouped by date like { '2020-01-01': [1, 2, 3], '2020-01-02': [4, 5, 6] }
  const dataGroupedByDate = data.reduce(groupByDate, {});

  // Helper methods to agregate data
  const calculateAverageForDate = (acc, curr) => ([...acc, [curr[0], calculateAverage(curr[1])]]);
  const calculateSumForDate = (acc, curr) => ([...acc, [curr[0], curr[1].reduce((p, c) => p + c, 0)]]);

  // Summarize data for each date
  if (summarize) {
    return Object.entries(dataGroupedByDate).reduce(calculateSumForDate, []);
  }

  // By default calculate average for each date
  return Object.entries(dataGroupedByDate).reduce(calculateAverageForDate, []);
};

export const getPlotData = ({
  rawData, xAttr, yAttr, label, color = '#f44336', legendGroup = null,
  isDateX = true, aggregate = false, summarize = false, withTrend = true, round = true,
}) => {
  if (!rawData || !rawData.length) {
    return [];
  }

  let x;
  let y;

  if (aggregate) {
    const data = prepareDataForPlot(rawData, xAttr, yAttr, summarize);
    x = data.map((i) => i[0]);
    y = data.map((i) => i[1]);
  } else {
    x = rawData.map((i) => i[xAttr]);
    y = rawData.map((i) => Number.parseFloat(i[yAttr]));
  }

  if (round) {
    y = y.map((i) => Math.round(i));
  }

  if (isDateX) {
    const year = x[0].slice(-4);
    // Remove years from date to make to shorter
    if (x.every((v) => v.slice(-4) === year)) {
      x = x.map((v) => v.slice(0, -5));
    }
  }

  const plotData = [
    {
      x,
      y,
      type: 'scatter',
      mode: 'lines+markers',
      line: { color },
      marker: { color },
      name: label,
      hovertemplate: '%{y}',
      legendgroup: legendGroup,
      // hoverinfo: 'x+y',
      // text,
    },
  ];

  // Calculate trends
  if (withTrend) {
    const [trendX, trendY] = calculateTrend(x, y);

    plotData.push({
      x: trendX,
      y: trendY,
      type: 'scatter',
      mode: 'lines+markers',
      line: { dash: 'dot' },
      marker: { color },
      name: `${label} (Trend)`,
      legendgroup: legendGroup,
    });
  }

  return plotData;
};

export const getPlotHorizontalLine = (
  x, level, color, label, legendgroup, showlegend = true,
) => {
  const y = Array(x.length).fill(level);

  return {
    x,
    y,
    type: 'line',
    mode: 'lines',
    marker: { color },
    name: label,
    // Explicit template to avoid cutting long names
    hovertemplate: '%{y}',
    line: {
      width: 2,
      dash: 'dash',
    },
    legendgroup,
    showlegend,
  };
};

export const mapUnits = (value, unitsFrom, unitsTo) => {
  if (unitsFrom === 'kPa' && unitsTo === 'mmHg') {
    return value * 7.5006157584566;
  }

  if (unitsFrom === 'mmHg' && unitsTo === 'kPa') {
    return value / 7.5006157584566;
  }

  if (unitsFrom === 'lbs' && unitsTo === 'kg') {
    return value * 0.45359237;
  }

  if (unitsFrom === 'kg' && unitsTo === 'lbs') {
    return value / 0.45359237;
  }

  return value;
};

export const getAlertZonesPlots = (alertZones, x, units, color = '#f44336', withLegend = true) => alertZones.map(
  (az, i) => [
    getPlotHorizontalLine(
      x,
      round(mapUnits(az.high_value, az.value_unit, units)),
      typeof color === 'function' ? color(az) : color,
      'Alert Zone (max)',
      `group_${i}`,
      withLegend,
    ),
    getPlotHorizontalLine(
      x,
      round(mapUnits(az.low_value, az.value_unit, units)),
      typeof color === 'function' ? color(az) : color,
      'Alert Zone (min)',
      `group_${i}`,
      withLegend,
    ),
  ],
);

export const calculateAverageForKey = (data, key) => {
  const values = data.map((i) => Number.parseFloat(i[key])).filter((i) => !Number.isNaN(i));
  const sum = values.reduce((a, b) => a + b, 0);

  return sum / values.length;
};

// const drawSummaryChart = (containerEl, data) => {
//   const width = 550;
//   const height = 400;

//   const nodes = [
//     {
//       label: data.first.value,
//       sublabel: 'BPM',
//       group: 1,
//       level: 1,
//       r: 50,
//       x: 265,
//       y: 260,
//       legend: {
//         x: 225,
//         y: 335,
//         firstLine: 'Initial',
//         secondLine: data.first.date,
//         line: {
//           x1: 40,
//           y1: -25,
//           x2: 40,
//           y2: 0,
//         },
//       },
//       color: '#0da532', // green
//     },
//     {
//       label: data.average.value,
//       sublabel: 'BPM',
//       group: 1,
//       level: 1,
//       r: 70,
//       x: 200,
//       y: 200,
//       legend: {
//         x: 15,
//         y: 170,
//         firstLine: 'Average',
//         secondLine: data.average.date,
//         line: {
//           x1: 80,
//           y1: 25,
//           x2: 115,
//           y2: 25,
//         },
//       },
//       color: '#f70d0d', // red
//     },
//     {
//       label: data.latest.value,
//       sublabel: 'BPM',
//       group: 2,
//       level: 1,
//       r: 90,
//       x: 300,
//       y: 150,
//       legend: {
//         x: 430,
//         y: 130,
//         firstLine: 'Latest',
//         secondLine: data.latest.date,
//         line: {
//           x1: -40,
//           y1: 25,
//           x2: 0,
//           y2: 25,
//         },
//       },
//       color: '#295bb4', // blue
//     },
//   ];

//   const svg = d3.select(containerEl)
//     .append('svg')
//     .attr('width', width)
//     .attr('height', height);

//   const elem = svg.selectAll('g').data(nodes);

//   const elemEnter = elem.enter()
//     .append('g')
//     .attr('transform', (d) => `translate(${d.x}, ${d.y})`);

//   // Circles
//   elemEnter.append('circle')
//     .attr('r', (d) => d.r)
//     .attr('stroke', (d) => d.color)
//     .style('opacity', 0.5)
//     .style('fill', (d) => d.color);

//   elemEnter.append('text')
//     .attr('text-anchor', 'middle')
//     .style('font-size', '1.5rem')
//     .text((d) => d.label);

//   elemEnter.append('text')
//     .attr('text-anchor', 'middle')
//     .attr('dy', 15)
//     .style('font-size', '.75rem')
//     .text((d) => d.sublabel);

//   // Labels
//   const labels = elem.enter()
//     .append('g')
//     .attr('transform', (d) => `translate(${d.legend.x}, ${d.legend.y})`);

//   labels.append('rect')
//     .attr('stroke', '#dddddd')
//     .attr('fill', '#dddddd')
//     .attr('rx', '5px')
//     .attr('ry', '5px')
//     .attr('width', 80)
//     .attr('height', 50);

//   labels.append('text')
//     .attr('text-anchor', 'middle')
//     .attr('dx', 40)
//     .attr('dy', 20)
//     .style('font-size', '1rem')
//     .style('font-weight', '500')
//     .text((d) => d.legend.firstLine);

//   labels.append('text')
//     .attr('text-anchor', 'middle')
//     .attr('dx', 40)
//     .attr('dy', 40)
//     .style('font-size', '1rem')
//     .text((d) => d.legend.secondLine);

//   labels.append('line')
//     .attr('stroke', '#ddd')
//     .style('stroke-dasharray', ('3, 3'))
//     .attr('x1', (d) => d.legend.line.x1)
//     .attr('y1', (d) => d.legend.line.y1)
//     .attr('x2', (d) => d.legend.line.x2)
//     .attr('y2', (d) => d.legend.line.y2);
// };

import React from 'react';
import { sendRequest } from '../../utils';
import Loader from '../../Components/Loader';
import SubscriptionInfo from './SubscriptionInfo';

const SubscriptionSettings = () => {
  const [subscription, setSubscription] = React.useState(null);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    sendRequest('subscriptions/current', 'GET')
      .then((resp) => {
        if (resp.status === 'success') {
          setSubscription(resp.data);
        } else {
          setError(resp.message);
        }
      })
      .catch(() => setError('Something went wrong'));
  }, []);

  if (!subscription && !error) {
    return <Loader />;
  }

  return (
    <div>
      {subscription ? (
        <div className="pt3">
          <SubscriptionInfo data={subscription} />
        </div>
      ) : (
        <>
          <h5 className="mb3">Subscription</h5>
          <div className="card-panel red lighten-5">
            <span className="red-text text-darken-2">
              {error || 'You do not have an active subscription'}
            </span>
          </div>
        </>
      )}
    </div>
  );
};

export default SubscriptionSettings;
